
import { defineComponent, onBeforeMount, onMounted, reactive, ref, resolveComponent } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { object } from "yup/lib/locale";

export default defineComponent({
  name: "student-activities",
  props: {
    widgetClasses: String,
  },
  components: {
  },
  setup() {
    const memos = ref<any>([]);
    const noOfRecords = 30;

    const getStudentActivities = async () =>  {
      return new Promise<void>((resolve, reject) => { 
        ApiService.setHeader();         
        ApiService.get("student/activities/"+noOfRecords)
            .then(({ data }) => {
              memos.value = data.data;
              resolve();
            })
            .catch(({ response }) => {
                console.log('getStudentMemo error => '+response.data.errors);                    
            });
      });
    }

    onBeforeMount(async() => {
      await getStudentActivities();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return{
      memos,
      moment
    }
  },
});
