<template>
  <div class="card" :class="widgetClasses" v-if="memos">
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">Event History</span>
      </h3>
    </div>
    <div class="card-body pt-5">
      <div id="student-activity-area" class="timeline-label" style="max-height:410px;overflow:auto;">
        <div v-for="memo in memos" :key="memo.id" class="timeline-item">
          <div class="timeline-label fw-bolder text-gray-800 fs-6">
            {{moment(memo.create_date).format('MM/DD/YYYY HH:mm')}}
          </div>
          <div class="timeline-badge">
            <i class="fa fa-genderless text-success fs-1"></i>
          </div>
          <div class="timeline-content fw-bolder text-gray-800 ps-3">
              {{memo.first_name + " " + memo.last_name + "("+ memo.student_id+") : " + memo.memo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, reactive, ref, resolveComponent } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { object } from "yup/lib/locale";

export default defineComponent({
  name: "student-activities",
  props: {
    widgetClasses: String,
  },
  components: {
  },
  setup() {
    const memos = ref<any>([]);
    const noOfRecords = 30;

    const getStudentActivities = async () =>  {
      return new Promise<void>((resolve, reject) => { 
        ApiService.setHeader();         
        ApiService.get("student/activities/"+noOfRecords)
            .then(({ data }) => {
              memos.value = data.data;
              resolve();
            })
            .catch(({ response }) => {
                console.log('getStudentMemo error => '+response.data.errors);                    
            });
      });
    }

    onBeforeMount(async() => {
      await getStudentActivities();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return{
      memos,
      moment
    }
  },
});
</script>

<style scoped>
*>>> .timeline-label .timeline-label
{
  width: 140px;
}
*>>> .timeline-label::before{
  left: 141px;
}

@media (min-width: 1400px) {
  #student-activity-area{
    max-height: 700px !important;
  }
}
</style>
