
  import { defineComponent, ref, watch } from "vue";
  import moment from "moment";
  
  export default defineComponent({
    name: "show-event-modal",
    components: {
    },
    props: {
        event: {} as any
    },
    setup(props) {
      const formRef = ref<null | HTMLFormElement>(null);
      const loading = ref<boolean>(false);
      const eventData = ref<any>({});
    
      watch(() => props.event, ()=>{          
          eventData.value = props.event;
      })  
  
      return {
        loading,
        formRef,
        moment,
        eventData
      };
    },
  });
  