<template>
    <div
      class="modal fade"
      id="modal_show_event"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-700px">
        <div class="modal-content">
          <div class="modal-header">
              <h2>
                  Event Info
              </h2>
  
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
  
          <div class="modal-body py-lg-10 px-lg-10">
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span class="required">Type</span>
              </label>
              <div class="col-lg-9">
                {{eventData.type}}
              </div>
            </div>
              <div class="row mb-6">
                <label
                  class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
                >
                  <span class="required">Title</span>
                </label>
                <div class="col-lg-9">
                    {{eventData.title}}
                </div>
              </div>
              <div class="row mb-6">
                <label
                  class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
                >
                  <span class="required">Date</span>
                </label>
                <div class="col-lg-9">
                    {{(eventData.start_date == eventData.end_date? moment(eventData.start_date).format('MM/DD/YYYY') : moment(eventData.start_date).format('MM/DD/YYYY') + " ~ " + moment(eventData.end_date).format('MM/DD/YYYY')) }}
                </div>
              </div>
              <div class="row mb-6">
                <label
                  class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
                >
                  <span>Description</span>
                </label>
                <div class="col-lg-9">
                    {{eventData.description}}
                </div>
              </div>
              <div class="text-center">
                <button
                  id="close_event_button"
                  class="btn btn-white me-3"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </template>
  
  
  <script lang="ts">
  import { defineComponent, ref, watch } from "vue";
  import moment from "moment";
  
  export default defineComponent({
    name: "show-event-modal",
    components: {
    },
    props: {
        event: {} as any
    },
    setup(props) {
      const formRef = ref<null | HTMLFormElement>(null);
      const loading = ref<boolean>(false);
      const eventData = ref<any>({});
    
      watch(() => props.event, ()=>{          
          eventData.value = props.event;
      })  
  
      return {
        loading,
        formRef,
        moment,
        eventData
      };
    },
  });
  </script>
  
  <style lang="scss" scoped>
  .el-input--suffix .el-input__inner {
    background-color: #f5f8fa;
  }
  
  .el-input__inner {
    background-color: #f5f8fa;
  }
  </style>
  
  <style scoped>
  * >>> .el-form-item {
    margin-bottom: 0px;
  }
  
  * >>> .el-table td {
      padding: 5px;
      font-size: 13px;
  }
  
  * >>> .el-table th {
      padding: 5px;
      font-size: 13px;
      line-height: 20px;
  }
  </style>
  
  