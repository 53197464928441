<template>
  <div class="row gy-5 g-xl-8" v-if="user.role != 'Faculty'">
    <div class="col-xxl-12">
      <TopInformation />
      <QuickLink
        widget-classes="card-xl-stretch mb-xl-8"
        widget-color="danger"
        chart-height="200"
        stroke-color="#cb1e46"
      ></QuickLink>
    </div>
  </div>
  <div class="row gy-5 g-xl-8 mt-7">
    <div class="col-xxl-6" style="margin-top:0;" v-if="user.role != 'Faculty'">
      <StudentActivities
        widget-classes="card-xxl-stretch mb-5 mb-xl-10"
      ></StudentActivities>
    </div>
    <div :class="user.role != 'Faculty' ? 'col-xxl-6': 'col-xxl-12'" style="margin-top:0;">
      <div class="card card-xxl-stretch mb-5 mb-xl-10">
        <div class="card-body">
          <a
                href="#"
                style="display:none;"
                data-bs-toggle="modal"
                data-bs-target="#modal_show_event"
                id="event_show_button"
          />
          <Calendar 
            v-bind:openShowModal="openShowModal"
            ref="calendarWidget"/>
        </div>
      </div>
    </div>
  </div>  
  <template v-if="transactionTypes">
    <CreateRecord 
        v-bind:transactionTypes="transactionTypes"
        v-bind:paymentMethodTypes="paymentMethods"
        v-bind:paymentTypes="paymentTypes"
        v-bind:students="students"
    ></CreateRecord>
  </template>
  <ShowEvent
        v-bind:event="selectedEvent">
  </ShowEvent>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import StudentActivities from "./dashboard/StudentActivities.vue";
import QuickLink from "./dashboard/QuickLink.vue";
import Calendar from "./widgets/Calendar.vue";
import TopInformation from "./dashboard/TopInformation.vue"
import CreateRecord from "@/views/financial/modals/CreateRecord.vue";
import ApiService from "@/core/services/ApiService";
import ShowEvent from   "@/views/dashboard/ShowEventModal.vue"
import { useStore } from "vuex";

export default defineComponent({
  name: "dashboard",
  components: {
    StudentActivities,
    QuickLink,
    Calendar,
    TopInformation,
    CreateRecord,
    ShowEvent
  },
  setup() {
    const transactionTypes = ref<any>([]);
    const paymentMethods = ref<any>([]);
    const paymentTypes = ref<any>([]);
    const students = ref<any>([]);
    const selectedEvent = ref({});
    const store = useStore();   
    const user = store.getters.currentUser; 
    
    const getTypes = async() => {
        ApiService.setHeader();
        await ApiService.get("finance/types")
                .then(({ data }) => {
                    transactionTypes.value = data.recordTypes;
                    paymentMethods.value = data.paymentMethods;
                    paymentTypes.value = data.paymentTypes;
                })
                .catch(({ response }) => {
                console.log('getTypes error => '+response.data.errors);
                });
    }

    const getStudents = async() =>{ 
        ApiService.setHeader();
        await ApiService.get("student/status/Active,Draft,COS,Initial,Reinstate,Exit")
                .then(({ data }) => {
                    students.value = data.data;
                })
                .catch(({ response }) => {
                console.log('getStudents error => '+response.data.errors);
                });
    }

    const openShowModal = (event) =>{
        selectedEvent.value = event;
        let showEventBtn = document.getElementById('event_show_button');
        showEventBtn?.click();
    }

    onMounted(async() => {      
      await getStudents();
      await getTypes();
      setCurrentPageTitle("Dashboard");
    });

    return {
      transactionTypes,
      paymentMethods,
      paymentTypes,
      students,  
      user,
      openShowModal,
      selectedEvent
    }
  },
});
</script>
